<template>
  <section style="overflow:hidden;min-height:auto;" ref="container">
    <div class="panel-content" @click.stop.prevent="onEdit" ref="content">
      <div
        class="overlay text-center"
        v-if="busy"
        style="padding:5%;font-size:18pt;color:gray;"
      >
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <img
        class="img"
        :class="imagePositionClass"
        :style="imageStyle"
        :title="$t(this.panel.title)"
        ref="img"
      />
    </div>
    <div
      :class="{
        'editor-toolbar': mode == 'editor',
        editing: isEditing
      }"
    >
      <span
        :class="isEditing ? 'clicable panel-title' : ''"
        @click.stop.prevent="$emit('panelProperties')"
      >
        <span v-if="isEditing && !this.panel.title" class="fa fa-pencil"></span>
        <span v-else> {{ $t(this.panel.title) }}</span>
      </span>
      <slot name="toolbar"></slot>
    </div>
  </section>
</template>

<script>
import { debounceTime } from "@/utils";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import ImageForm from "@/components/control-sidebar/property-editors/image-form.vue";
const missingImg =
  "data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAwIDEwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik01MCwzMWMtMTAuNDkzLDAtMTksOC41MDctMTksMTlzOC41MDcsMTksMTksMTlzMTktOC41MDcsMTktMTlTNjAuNDkzLDMxLDUwLDMxeiBNNjEsNTNIMzl2LTZoMjJWNTN6Ij48L3BhdGg+PC9zdmc+";

const ResizeObserver = window.ResizeObserver || Polyfill;

export default {
  name: "DashboardImagePanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {},
  data() {
    return {
      busy: false
    };
  },
  computed: {
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    size() {
      return this?.panelOptions?.image?.size || "100%";
    },
    src() {
      return this?.panelOptions?.image?.src || "";
    },
    imageStyle() {
      return { height: this.size };
    },
    imagePositionClass() {
      let classes = {};
      classes[this.panelOptions.image.align] = true;
      return classes;
    }
  },
  watch: {
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "ImageForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: ImageForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
          }
        }
      },
      immediate: true
    },
    sidebar: {
      handler(n) {
        if (this.isEditing && n && n.name == "ImageForm") {
          this.trigger({
            action: "image:activate",
            details: { panelName: this.panel.name }
          });
        }
      },
      deep: true
    },
    src: "loadImage"
  },
  methods: {
    loadImage(callback) {
      const cb = callback || function() {};
      if (this?.$refs?.img) {
        if (this.src) {
          this.busy = true;
          this.$utils.loadImage(this.src, true).then((img) => {
            if (this.$refs.container.style.minHeight == "auto") {
              let h =
                parseInt(this.$el.parentNode.parentNode.offsetHeight) - 10;
              this.$refs.container.style.minHeight = `${h}px`;
            }
            if (img && this.$refs.img) {
              this.$refs.img.src = img.src;
            }
            this.busy = false;
            cb();
          });
        } else {
          this.busy = false;
          this.$refs.img.src = missingImg; // important: do not cache it on computed properties
          cb();
        }
      }
    },
    onEdit() {
      if (this.mode != "editor") return;
      this.trigger({
        action: "image:activate",
        details: { panelName: this.panel.name }
      });
    },
    trigger(details) {
      this.setSideBar();
      this.$nextTick(() => {
        this.$root.$emit("image:event", details);
      });
    },
    setSideBar() {
      if (this.sidebar.name != "ImageForm") {
        this.$root.$emit("controlSidebar:setContent", ImageForm);
      }
    },
    updateContainerHeight() {
      if (this.$refs.container) {
        this.$refs.content.style.height =
          this.$refs.container.getBoundingClientRect().height + "px";
      }
    },
    panelChangeMonitor() {
      let self = this;
      self._height = 0; // not reactive
      const _updateContainerHeight = debounceTime(100, function() {
        self.updateContainerHeight();
      });
      const sizeWatcher = new ResizeObserver((els) => {
        if (!self.busy && self.$refs.container) {
          let el = els && els.length ? els[0] : null;
          let h = parseInt(
            (el && el?.target?.getBoundingClientRect()?.height) || 0
          );
          if (h && h != self._height) {
            self._height = h;
            _updateContainerHeight();
          }
        }
      });

      let _p = this.$refs.container;
      while (!_p.classList.contains("inner-panel")) {
        _p = _p.parentElement;
        if (!_p) break;
      }
      if (_p) {
        sizeWatcher.observe(_p);
      }
    }
  },
  mounted() {
    this.$refs.container.style.minHeight = "auto";
    this.loadImage(() => {
      this.panelChangeMonitor();
    });
    if (this.isEditing) {
      this.setSideBar();
    }
  }
};
</script>

<style scoped>
.panel-title {
  padding-left: 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.editor-toolbar {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
  background-color: transparent;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}

.panel-content {
  z-index: 0;
  /* min-height: 10px; */
  position: relative;
}

.img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.top-center {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

.bottom-center {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.middle-left {
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.middle-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middle-right {
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
}
</style>
